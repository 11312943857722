import { GetStaticPropsContext, InferGetStaticPropsType } from "next";

import EditorialEntry from "~/components/editorials/editorial-entry";
import LayoutPage from "~/components/layout/layout-page";
import { usePageTracking } from "~/contexts/pagetrackings";
import Constants from "~/utils/constants";
import { isActiveEntry } from "~/utils/editorial-utils";
import ssrUtils from "~/utils/ssr-utils";
type Props = InferGetStaticPropsType<typeof getStaticProps>;

export default function IndexPage(props: Props) {
  usePageTracking("Home", { title: props.page.seoMetadata?.fields.seoTitle });

  return (
    <LayoutPage {...props}>
      <>
        {props.page.components?.filter(isActiveEntry).map((entry) => (
          <EditorialEntry key={entry.sys.id} entry={entry} />
        ))}
      </>
    </LayoutPage>
  );
}

export async function getStaticProps(context: GetStaticPropsContext) {
  if (context.locale === context.defaultLocale) {
    return {
      notFound: true,
    };
  }

  const pageData = await ssrUtils.getServerSideEditorialPageData(
    context,
    context.draftMode,
    process.env.NEXT_PUBLIC_HOMEPAGE_SLUG!
  );

  if (!pageData.page) {
    return {
      notFound: true,
      revalidate: Constants.DEFAULT_STATIC_PAGE_TIME,
    };
  }

  return {
    props: {
      ...pageData,
    },
    revalidate: Constants.DEFAULT_STATIC_PAGE_TIME,
  };
}
